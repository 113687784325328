@import "src/assets/styles/variables";

.wrapper {
  width: 100%;
  padding: $spacer-3;
  box-shadow: inset 0px -1px 1px -1px $color-black;
  background-color: $color-grey-dark-4;
  border: 1px solid $color-grey-dark-5;
  border-radius: 4px;
  color: $color-white;

  .title {
    font-size: 20px;
    margin: 0 0 $spacer-2;
  }

  .content {
    display: flex;
    align-items: center;
    padding: $spacer-3;

    .chart {
      width: 170px;
      padding: 0 20px 0 0;
    }

    .labels {
      display: flex;
      flex-wrap: wrap;

      .label {
        color: $color-grey-light-3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: $spacer-2 0;
        cursor: default;
        line-height: 1.2;
        width: 50%;
      }

      .text {
        width: calc(100% - 30px);
      }

      .mark {
        border-radius: 10px;
        height: 20px;
        width: 20px;
      }
    }
  }
}
