.empty-box-content {
  font-size: 18px;
  padding: 20px;
  cursor: default;

  svg {
    height: 80px;
    width: 80px;
  }
}
