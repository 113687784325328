@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.quotation-analysis-page {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  min-height: 400px;

  .quotation-analysis-info {
    width: calc(100% - 320px);

    .table {
      margin: 24px 0 0;
    }

    .table-scrolling .ant-table .ant-table-container {
      min-width: 850px;
    }

    .table-bottom {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0;
    }
  }

  .hbh-details-container .hbh-details-item-value {
    overflow: visible;
  }

  .reset {
    cursor: pointer;
    margin: 0 auto;
    width: fit-content;
    color: $color-blue-primary;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }
  }
}

.filter-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-reset {
  color: $color-blue-primary;
  text-decoration: underline;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;

  &.filter-reset-general {
    width: fit-content;
    font-size: 14px;
    margin: 0 auto;
  }

  &:hover {
    text-decoration: none;
  }
}

.block-plate {
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  line-height: 1.2;
  padding: 6px 12px;

  &.bp-yes {
    background-color: $color-green-grass;
  }
  &.bp-no {
    background-color: $color-red-dark-1;
  }
}

.button-export {
  @include applyButtonColor(#57a300);
}
