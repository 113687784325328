@import '@assets/styles/variables.scss';

.no-access-page {
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  .message-container {
    margin-top: 15vh;
    margin-left: 10vw;
    color: $color-black;
    width: 850px;

    svg {
      fill: $color-black;
    }

    .text {
      font-weight: 300;
      font-size: 40px;
    }
  }
}
