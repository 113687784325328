@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.letters-page {
  min-height: 400px;

  .table {
    .ant-table-thead {
      tr {
        th:last-child {
          width: 100px;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td:last-child {
          text-align: right;

          button {
            display: inline-block;
          }
        }
      }
    }
  }
}
