@import "src/assets/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: $spacer-3;
  box-shadow: inset 0px -1px 1px -1px #000;
  background-color: #262829;
  border: 1px solid #171717;
  border-radius: 4px;
  color: $color-white;

  .title {
    font-size: 20px;
    margin: 0 0 $spacer-2;
  }

  .labels {
    display: flex;
    align-items: center;
    margin: $spacer-2 0;
    flex-wrap: wrap;

    .label {
      display: flex;
      align-items: center;
      margin: 0 $spacer-3 $spacer-2 0;

      .dot {
        width: 18px;
        height: 18px;
      }

      .text {
        padding: 0 $spacer-2;
        line-height: 18px;
        cursor: default;
      }
    }
  }

  .content {
    height: 300px;
    width: 100%;
  }
}

.tooltip {
  text-align: center;
  padding: $spacer-1 $spacer-2;
  line-height: 1.4;
  border-radius: 6px;
  background-color: #000000b8;
}
