@import "@assets/styles/mixins.scss";
@import "@assets/styles/variables.scss";

$quotation-table-cell-height: 51px;

.id-wrapper {
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
}

.assignment-list-scroll-wrapper {
  width: calc(100% + 40px);
  margin: 0 -20px;

  &:hover {
    .scroll-button {
      opacity: 1;
    }
  }

  .ant-table-scroll-horizontal {
    background-color: #333536;
  }
}

.assignment-list-wrapper {
  margin: 30px 0;
  position: relative;
  padding: 0 20px;

  hr {
    border-color: #323536;
  }

  .label {
    font-size: 16px;
    margin: 25px 0;
  }

  .table-element-wrapper {
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.selected {
      background-color: #018720;
      border-color: #018720;
    }

    span {
      margin-left: 10px;
    }

    .tooltip-icon {
      margin-right: 10px;
    }
  }

  .table-element-centered {
    text-align: center;
  }

  .table-element-currency {
    display: flex;
    align-items: center;

    svg {
      margin-left: 6px;
    }
  }

  .ant-table-content {
    overflow: hidden !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      text-align: center;
    }
  }

  .arrow-buttons-container {
    position: absolute;
    width: 100%;
    top: calc(50% + 40px);
  }

  .scroll-button {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    transition: 0.5s;
    opacity: 0;

    &.right {
      right: 0;
    }

    &.left {
      left: -40px;
      transform: rotate(180deg);
    }

    &.disabled {
      filter: grayscale(1);
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  .assignment-table-top {
    display: flex;
    margin: 0 0 20px;
    justify-content: space-between;
    align-items: center;

    .dropdown-sort {
      display: flex;
      margin: 0 15px 0 0;

      .reset-sorting-btn {
        background: none;
        color: #3a89f7;
        margin: 0 0 0 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .switcher-cost {
      margin-right: auto;
    }

    .buttons {
      display: flex;

      .button-cancel {
        margin: 0 20px 0 0;
      }

      .button-amend {
        @include applyButtonColor(#0a6eb1);
        margin: 0 0 0 20px;
      }

      .button-history {
        @include applyButtonColor(#757575);
      }
    }
  }
}

.table-wrapper {
  display: flex;

  > .ant-spin-nested-loading {
    width: calc(100% - 100px);
  }

  .line-counter {
    width: 100px;
    text-align: center;
    flex-shrink: 0;

    &-header {
      background: #434a4e;
      color: #c5d3e2;
      font-size: 12px;
      padding: 16px 16px;
      height: $quotation-table-cell-height;
    }

    &-cell {
      background: #262829;
      border-bottom: 1px solid #37393a;
      font-size: 14px;
      padding: 15px 16px;
      height: $quotation-table-cell-height;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: default;

      .sorting-button {
        background: none;
        height: auto;
        padding: 0;
        opacity: 0.8;
        transition: 0.2s opacity linear;

        &.active {
          svg {
            fill: #3a89f7;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .ant-table-cell {
    vertical-align: middle;
    text-align: center;
    height: $quotation-table-cell-height;
    max-width: 100px;

    @include text-cropping;
  }
}

.modal-amendment-content {
  p {
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
  }

  .textarea-amendment-reason {
    width: 100%;
    resize: none;
    height: 70px;
    color: #000;
    margin: 0 0 20px;
  }

  .button-amendment-upload {
    width: 100%;
  }
}

.ant-upload-list-item {
  color: #fff;

  svg {
    fill: #fff;
  }

  &:hover .ant-upload-list-item-info {
    background-color: transparent !important;
    text-decoration: underline;
  }
}

.ant-upload {
  display: block;
  width: 100%;
}

.modal-note {
  font-size: 12px;
  font-style: italic;
  color: gray;
  cursor: default;
}

.qm-title {
  padding: 10px 0;
  border-top: 2px solid $color-grey-dark-2;
  font-weight: 600;
}
