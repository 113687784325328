@import "../../assets/styles/variables";

.hbh-container {
  width: 75vw !important;
}

.title {
  display: flex;
  align-items: center;
  line-height: 1;

  .title-divider {
    text-align: center;
    width: 50px;
  }

  .title-box {
    display: flex;
    align-items: center;
    margin: 0 30px 0 0;

    span {
      margin: 0 0 0 8px;
    }
  }
}

.section-title:before {
  content: url("../../assets/svg/WFPLogoIcon.svg");
  margin-right: 20px;
  margin-top: 5px;
}

.ant-table-row:hover {
  cursor: pointer;
}

.tabs {
  .hbh-tab-select {
    background-color: darken($color-grey-dark-2, 2%);
    color: lighten($color-grey-light-1, 10%);
  }

  @at-root .ant-tooltip-content {
    background-color: #000000;
  }
}

.empty-box {
  background-color: #f9f9f9;
  color: #818181;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  cursor: default;

  svg {
    margin: 0 10px 0 0;
  }
}

.confirm-button {
  width: 250px;
  height: 50px;
  margin-left: auto;
}

.icon-mt {
  width: 20px;
  height: 20px;
}

.total-overall {
  font-weight: 800;
}

.modal-text {
  margin: 0 0 8px;
  line-height: 1;
  text-align: center;
}

.textarea-multiple-selection-reason {
  width: 100%;
  resize: none;
  height: 70px;
  color: $color-black;
}
