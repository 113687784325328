.assignment-overview-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 30px;

  .cost-overview {
    width: 30%;
  }

  .overall-details {
    width: 70%;

    p {
      margin-bottom: 15px;
    }

    .overall-details-list {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
}

.chart {
  &-wrapper {
    display: flex;
    align-items: center;
    padding: 30px 0 20px;
  }

  &-doughnut {
    width: 170px;
    padding: 0 20px 0 0;
  }

  &-label {
    color: #b8bfc4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    cursor: default;
    line-height: 1.2;
  }

  &-text {
    width: calc(100% - 30px);
  }

  &-mark {
    border-radius: 10px;
    height: 20px;
    width: 20px;
  }
}
