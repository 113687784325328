@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: $spacer-3;
  cursor: default;
  box-shadow: inset 0px -1px 1px -1px #000;
  background-color: #262829;
  border: 1px solid #171717;
  border-radius: 4px;

  .label {
    color: $color-grey-light-3;
    font-size: 12px;
    margin-bottom: $spacer-2;
    text-transform: uppercase;
  }

  .value {
    @include text-cropping;
    color: $color-white;
    font-size: 16px;
  }
}
