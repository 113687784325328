@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.menu {
  width: 40vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: $color-white;

  &.centered {
    margin: 100px auto;
  }

  .link {
    border-right: 1px solid $color-grey-dark-1;
    border-bottom: 1px solid $color-grey-dark-1;
    background: rgba(29, 31, 32, 0.92);
    padding: $spacer-4;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: normal;
    min-height: 220px;
    height: 100%;
    color: inherit;

    .content {
      display: flex;
      flex-direction: column;
      gap: $spacer-3;

      .icon {
        svg {
          fill: $color-loblolly;
          width: 30px;
          height: 24px;
        }
      }

      .label {
        font-size: 18px;
        font-weight: 700;
      }

      .description {
        font-size: 14px;
        line-height: 1.5;
      }

      .tag {
        border-radius: 12px;
        width: fit-content;
        padding: 2px 12px;
        line-height: 1.2;

        &:not(:last-child) {
          margin: 0 0 $spacer-1;
        }

        &.brand {
          background-color: $color-brand;
        }
        &.limeade {
          background-color: $color-limeade;
        }
        &.red {
          background-color: $color-red-dark-1;
        }
      }
    }

    .arrow {
      align-self: flex-end;
      flex-shrink: 0;

      path {
        fill: #0b77c2;
      }
    }

    &:hover {
      background: rgba(29, 31, 32, 0.86);
      transition: 0.35s all ease-in-out;
      .arrow {
        transition: 0.25s all ease-out;
        path {
          fill: $color-brand;
        }
        transform: scale(1.1) translateX(5px);
      }
    }
  }
}
