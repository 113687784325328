.bulk-menu-item {
  background-color: #3E3E3E !important;
  color: #fff !important;
  display: flex !important;
  z-index: 1;

  .bulk-radio {
    margin-right: 10px;
    accent-color:green !important;

    .ant-radio-inner {
      background-color: transparent;

      &:after {
        background-color: green !important;
      }
    }
  }
}