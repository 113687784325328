@import "../../../../assets/styles/variables";

.vendor-information-page {
  min-height: 400px;

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0;

    button {
      padding: 20px 60px;
    }
  }

  .vendor-details {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    :last-child .hbh-details-item-value {
      overflow: visible;
    }
  }
}

.vendor-info-block {
  border-top: 3px solid #434a4e;
  padding: 20px 0 0;
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: end;

  .button-add-contract {
    margin: 10px 0 0;
  }
}

.date-cell {
  display: flex;
  align-items: center;

  .exp-dot {
    background-color: $color-green-grass;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;

    &.expired {
      background-color: $color-red-dark-1;
    }
  }
}

.add-new-contract-form {
  label {
    display: block;
    margin: 10px 0 5px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.vendor-status-info {
  display: flex;
  justify-content: space-between;
}

.vendor-details small {
  display: block;
}
