@import "src/assets/styles/variables";

// Example
// <div class="react-select-container">
//   <div class="react-select__control">
//     <div class="react-select__value-container">...</div>
//     <div class="react-select__indicators">...</div>
//   </div>
//   <div class="react-select__menu">
//     <div class="react-select__menu-list">
//       <div class="react-select__option">...</div>
//     </div>
//   </div>
// </div>

.async-select-wrapper {
  width: 100%;

  .async-select {
    .async-select {
      background-color: $color-white;
      text-align: left;

      &__control {
        border-radius: 0;
        height: 30px;
        min-height: auto;
        cursor: pointer;
        border: 1px solid $color-black;

        &--is-disabled {
          background-color: $color-blue-light;

          .slcf__single-value {
            color: $color-blue-dark-1;
          }
        }
      }

      &__dropdown-indicator {
        padding: 0;
        margin-right: 8px;
      }

      &__value-container {
        padding: 0 4px;
        font-size: 12px;
      }

      &__control--menu-is-open .async-select__dropdown-indicator {
        transform: rotate(180deg);
      }

      &__indicators {
        height: 28px;
      }

      &__indicator {
        padding: 0;
        margin-right: 6px;
      }

      &__menu {
        overflow: hidden;
      }

      &__menu-list {
        padding: 0;
      }

      &__option {
        cursor: pointer;
        color: $color-black;
        padding: 4px 6px;
        font-size: 12px;

        &:hover {
          background-color: $color-blue-primary;
          color: $color-white;

          div {
            color: $color-white;
          }
        }
      }

      &__single-value {
        color: $color-black;
      }
    }
  }
}
