.format-number-field {
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
  color: black;
  font-size: 12px;
  line-height: 1.4;
  transition: 0.3s background-color linear;
  outline: 0;
  height: 30px;
  border: none;

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: auto;
    height: 25px;
    cursor: pointer;
  }
}

.error {
  margin: 0;
  font-size: 9px;
  position: absolute;
}
