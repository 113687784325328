.batch-report {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 28.5px;
  font-size: 13px;
  align-items: center;

  .range-label {
    margin-right: 10px;
  }

  .export-button {
    background-color: #139200;
    width: 150px;
    height: 32px;
    margin-top: 10px;
  }

  .calendar-icon {
    fill: #0a6eb4;
    width: 14px;
    height: 15px;
  }

  .award-toggle {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  strong {
    font-weight: 800;
  }

  .loader-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    font-size: 14px;
  }
}
