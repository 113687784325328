@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.btn {
  align-items: center;
  background: none;
  border-radius: 0;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: 0;
  transition: 0.2s all linear;

  background-color: $color-dodger-blue;
  color: $color-white;

  span {
    line-height: 0;
  }

  .icon {
    margin-right: 8px;
  }

  svg {
    transition: 0.2s all linear;
    fill: $color-white;
  }

  &:hover {
    text-decoration: none;
    color: $color-white;
  }
}

// Type variants
.btn-transparent {
  border: 1px solid $color-white;
  color: $color-white;
  background: none;

  svg {
    fill: $color-white;
  }

  &:hover {
    background-color: $color-selago;
    color: $color-mirage;

    svg {
      fill: $color-mirage;
    }
  }
}

.btn-icon {
  padding: 0;
  border: 0;
  background: none;
  height: auto;
  line-height: inherit;

  .iconWrap {
    margin-right: 0;
  }
}

.btn-link {
  border: 0;
  background: none;
  padding: 0;

  &:hover {
    color: $color-dodger-blue;
  }
}

.btn-text {
  background: none;
  color: inherit;
  border: 0;
  text-decoration: underline;
  padding: 0;
  display: inline;
  font-size: inherit;
  line-height: inherit;

  &:hover {
    text-decoration: none;
  }
}

.btn-disabled {
  pointer-events: none;
  opacity: 0.4;
}

@mixin new-btn-default {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $spacer-0 $spacer-3;
  gap: $spacer-2;
  min-height: px-to-rem(48);
  font-weight: 600;
  font-size: px-to-rem(14);
  line-height: px-to-rem(21);
  align-items: center;
  text-align: center;
  white-space: nowrap;

  svg {
    path {
      fill: currentColor;
    }
  }
  .icon {
    margin-right: 0;
  }
}

.btn-primary {
  @include new-btn-default;
  background: $color-cerulean;
  color: $color-white;
  &:hover,
  &:focus,
  &:active {
    background: $color-cerulean;
  }
  &:disabled {
    background: $color-cerulean;
    color: $color-cerulean;
  }
  &.btn-small {
    padding: $spacer-0 $spacer-3;
    min-height: px-to-rem(30);
  }
}

// Color variants
$colors: (
  "allports": $color-allports,
  "limeade": $color-limeade,
);

@each $name, $color in $colors {
  .btn-#{$name} {
    @include btn-color($color);
  }
}
