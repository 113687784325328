@mixin applyButtonColor($color) {
  background-color: $color !important;
  border-color: $color !important;

  &:hover {
    background-color: lighten($color, 10%) !important;
    border-color: lighten($color, 10%) !important;
  }
}

@mixin text-cropping {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin btn-color($color) {
  background-color: $color;

  &:hover {
    background-color: lighten($color, 10%);
  }
}
