@import "@assets/styles/mixins.scss";

.overview-list-container {
  position: relative;

  .scroll-button {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    transition: 0.5s;
    opacity: 0;

    &.right {
      right: 30px;
      top: 30%;
    }

    &.left {
      left: 30px;
      top: 30%;
      transform: rotate(180deg);
    }

    &.disabled {
      filter: grayscale(1);
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &:hover {
    .scroll-button {
      opacity: 1;
    }
  }

  .hbh-details-container {
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  .hbh-details-item {
    width: 200px;
  }

  .hbh-details-item-label {
    display: none;
  }
}

.quotation-overview-label {
  margin-bottom: 20px;

  span {
    font-weight: 800;
  }
}

.overview-item-container {
  .label {
    color: #6e7c8b;
    font-size: 12px;
    margin: 10px 0;
    display: block;
  }

  .remarks {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;

    &.disabled {
      color: #6e7c8b;
      text-decoration: none;
    }

    svg {
      margin-left: 5px;
    }
  }

  .provider-title {
    font-weight: 800;
    margin-bottom: 15px;
    display: block;
    width: 100%;

    @include text-cropping;
  }
}

.overview-details-container {
  overflow-x: hidden;
}

.arrow-buttons-container {
  &.hidden {
    display: none;
  }
}
