@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.container {
  position: relative;
  z-index: 1;
  width: 100%;

  .field {
    border: 1px solid $color-grey-dark-2;
    cursor: pointer;
    height: 30px;
    padding: 3px 20px 3px 4px;
    position: relative;
    @include text-cropping;

    .placeholder {
      color: #808080;
    }

    .icon {
      position: absolute;
      top: 6px;
      right: 8px;
    }
  }

  .menu {
    position: absolute;
    top: 35px;
    box-shadow: 3px 3px 6px 0 rgb(199 199 199 / 60%);
    border-radius: 4px;
    background-color: $color-white;
    padding: $spacer-1 0;
    width: 100%;

    .item {
      display: flex;
      align-items: center;
      margin: 0 0 $spacer-1;
      gap: $spacer-1;
      cursor: pointer;
      height: 30px;
      padding: 0 $spacer-2;

      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }

      .input {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        cursor: pointer;
      }

      .label {
        display: flex;
        align-items: center;
        gap: $spacer-1;
        line-height: 1;
        cursor: pointer;
        width: 100%;

        .order {
          color: $color-blue-primary;
          font-style: italic;
          font-size: 14px;
        }
      }
    }
  }

  &.disabled {
    .field {
      background-color: $color-alto;
      pointer-events: none;
      color: $color-grey-light-3;

      .icon {
        fill: $color-grey-light-3;
      }
    }
  }
}
