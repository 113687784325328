.vendors-list {
  .hbh-page-title {
    flex-wrap: wrap;
  }

  .status-filter-dropdown {
    .input-label {
      color: #fff;
      font-size: 14px;
      margin: 0 0 5px;
      cursor: default;
    }

    .reset {
      margin: 20px 0 0;
    }
  }

  .hbh-select-label {
    font-size: 14px;
    margin: 0 0 5px;
    line-height: 1.5;
    cursor: default;
  }

  .hbh-filters {
    width: 100%;
  }

  .status-filter {
    height: auto;
  }

  .action-buttons {
    display: flex;
    column-gap: 30px;
    align-self: center;

    .add-button {
      justify-self: flex-end;
    }
  }
}
