@import "../../assets/styles/variables";

.view-button {
  width: 100%;
}

.filters {
  display: flex;
  margin-top: 0;
  background-color: darken($color-grey-dark-2, 2%);
  padding: 15px 20px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 20px;

  .status-filter-dropdown {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
    row-gap: 20px;

    .status-filter {
      min-width: 200px;
    }

    .reset {
      align-self: center;
    }

    .hbh-select-label {
      color: #fff !important;
    }
  }

  .reset {
    cursor: pointer;
    font-size: 14px;
    width: max-content;
    color: $color-blue-primary;
    text-decoration: underline;
    flex-shrink: 0;
    margin-right: auto;
  }
}
