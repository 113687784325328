@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.generate-letter-page {
  .container {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 20px;

    .hbh-select-label {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .head {
      display: flex;
      border-bottom: 2px solid #b8b8b8;
      justify-content: space-between;

      .head-left {
        width: 70%;
      }

      .head-right {
        width: 25%;
        padding: 77px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .value {
          line-height: 30px;
        }
      }

      .dropdown {
        margin: 0 0 20px;
        font-size: 16px;
        height: 30px;

        &.hbh-select .rs__value-container {
          padding: 0;
        }

        .rs__input-container {
          margin: 0;
          padding: 0;
        }

        .rs__single-value,
        .rs__placeholder {
          margin: 0 0 0 5px;
        }

        &.dropdown-vendor {
          width: 60%;
        }
      }

      .signature {
        display: flex;
        gap: 20px;
        margin: 0 0 20px;

        .signature-sign {
          width: 40%;
        }

        .signature-approvers {
          width: 60%;
        }
      }
    }

    .content {
      padding: 20px 0 0;

      .table-navigation {
        display: flex;
        justify-content: space-between;
        margin: 0 0 20px;

        .hbh-date {
          opacity: 0.6;
          color: $color-black;
        }

        .filter-block {
          display: flex;
          align-items: end;

          .dropdown {
            width: 150px;
            height: 30px;
          }

          .dropdown-date {
            margin: 0 20px 0 0;
          }

          .reset {
            padding: 0;
            background: 0;
            border: 0;
            cursor: pointer;
            font-size: 14px;
            width: max-content;
            color: #0288ff;
            text-decoration: underline;
            flex-shrink: 0;
            margin: 0 20px 4px 0;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .hbh-table-light .ant-table-content .ant-table-thead th.ant-table-cell {
        background-color: #0ad7ba;
        color: #000;
        padding: 10px;
      }

      .hbh-table-light .ant-table-content .ant-table-tbody td {
        background-color: #fff;
        padding: 10px;
        border: 0;
      }

      .anticon svg {
        fill: black;
      }

      .buttons {
        display: flex;
        margin: 20px 0 0;

        .button-confirm {
          margin: 0 0 0 auto;
        }
      }

      .empty {
        background-color: #daeaf8;
        text-align: center;
        cursor: default;
        font-size: 20px;
        color: #939393;
        padding: 60px 0;
        border-radius: 8px;
      }
    }

    .pagination {
      margin: 20px 0;
    }
  }
}

.modal {
  &-content {
    margin: 0 0 20px;
    font-size: 20px;
    color: #cbcbcb;
    text-align: center;
    b {
      font-weight: 700;
      color: #fff;
    }
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
  }
}

.icon-approval {
  width: 70px;
  height: 70px;
  margin: 0 auto 20px;
  display: block;
}

.button-preview {
  @include applyButtonColor(#00ab7d);
}

.hbh-loader {
  background-color: #fff;
  margin: 20px auto;
  width: 200px;
  border-radius: 40px;
  padding: 20px 25px;
}
