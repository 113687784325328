.batch-summary {
  color: #fff;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 4px;

  .breaking-line {
    border-left: 1px solid #A4A4A4;
    height: 17px;
    width: 1px;
  }

  .status-dot {
    background-color: gray;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

    &.active {
      background-color: #0CB800;
    }
  }

  .status {
    font-size: 13px;
    font-weight: 200;

    .value {
      font-weight: 400;
    }
  }

}