@import "../../assets/styles/variables";

.filter-bar {
  background-color: #434a4e;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: space-between;

  .input-wrapper {
    width: 400px;
    color: $color-white;
    font-size: 14px;

    .input-label {
      margin-bottom: 5px;
    }

    .search-input {
      width: 100%;
      margin-bottom: 10px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .export-button {
    padding: 15px;
  }
}

.contract-cell {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-assignments .hbh-table-dark .ant-table-content {
  .ant-table-thead {
    th {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
      }

      &:nth-last-child(2) {
        text-align: center;
      }
    }
  }

  .ant-table-tbody {
    td {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
      }

      &:nth-last-child(2) {
        text-align: center;
      }
      &:nth-last-child(1) {
        padding-left: 0;
      }
    }
  }
}
