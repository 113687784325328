@import '@assets/styles/variables.scss';

.not-found-page {
  padding: 0 5%;
  min-height: calc(100vh - 125px);
  background-color: #08558b;
  background-image: url('../../assets/svg/icon_logo.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 600px;
  background-attachment: fixed;

  &--full-height {
    min-height: 100vh;
  }

  &__textbox {
    margin-top: 120px;
    transition: left 0.5s ease-out, opacity 0.5s ease-out;
    max-width: 440px;
    position: relative;

    h1 {
      font-size: 36px;
      line-height: 46px;
      color: $color-white;
    }

    hr {
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
      border: 0;
      margin: 35px 0;
    }

    p {
      transition: color 0.2s linear;
      font-size: 18px;
      line-height: 27px;
      color: $color-white;
    }

    .buttons-container {
      display: flex;
      margin-top: 45px;

      .button-link {
        transition: color 0.2s linear, border-color 0.2s linear;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        border: 2px solid white;
        border-radius: 99px;
        padding: 8px 30px 9px;
        display: inline-block;
        color: rgba(255, 255, 0, 1) !important;
        border-color: rgba(255, 255, 0, 1) !important;

        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
}
