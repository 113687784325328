.switcher-wrapper {
  display: flex;
  align-items: center;
  cursor: default;
  height: 24px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .switcher {
    position: relative;
    padding: 0 0 0 40px;
    cursor: pointer;
    color: #000;

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 0;
      width: 40px;
      height: 22px;
      border-radius: 11px;
      background: #5f5f5f;
      box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
      transition: 0.2s;
    }

    &:after {
      content: "";
      position: absolute;
      top: -8px;
      left: 2px;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background: #05dc27;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      transition: 0.2s;
    }
  }

  .switcher-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0;
  }

  .switcher-label {
    color: #cccccc;
    font-size: 14px;
    margin: 0 10px 0 0;
  }

  .switcher-text {
    color: #9c9c9c;
    font-size: 14px;
    transition: 0.2s all linear;

    &.st-left {
      padding: 0 10px 0 0;
    }

    &.st-right {
      padding: 0 0 0 10px;
    }

    &-active {
      color: #fff;
    }
  }
}

.switcher-input:checked + .switcher:after {
  left: 20px;
}
