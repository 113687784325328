@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.container {
  width: 96vw;
  margin: $spacer-4 0 0;

  :global .ant-picker {
    width: 100%;
    height: 30px;
    padding: $spacer-1 $spacer-3 $spacer-1;
    border-color: $color-white;
    box-shadow: none;

    .ant-picker-suffix {
      svg {
        fill: $color-white;
      }
    }

    .ant-picker-input > input {
      font-size: 12px;
      text-align: center;
    }
  }
}

.content {
  padding: $spacer-4;
  background-color: $color-grey-middle-2;
  color: $color-white;
  display: flex;
  flex-direction: column;
  gap: $spacer-4;

  .filter {
    padding: 0 0 $spacer-4;
    border-bottom: 1px solid $color-gravel;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: $spacer-4;

    .field {
      width: 20%;
      position: relative;

      .label {
        font-size: 14px;
      }

      .dropdown {
        width: 100%;
      }

      .resetButton {
        color: $color-primary;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        bottom: -17px;
        left: calc(50% - 33px);
      }
    }

    .contractOption {
      text-wrap: wrap;
    }

    :global .async-select__single-value {
      div {
        @include text-cropping;
      }
    }

    .locationOption {
      display: flex;
      align-items: flex-start;

      .info {
        color: $color-dusty-gray;
        font-size: 12px;
        margin-left: $spacer-1;
      }
    }
  }

  .row {
    display: flex;
    gap: $spacer-4;

    .block {
      width: 50%;

      table {
        th:nth-child(2),
        th:nth-child(3),
        th:nth-child(4),
        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(4) {
          text-align: center;
        }
      }
    }
  }
}
