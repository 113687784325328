@import "../../assets/styles/variables";

.hbh-container {
  width: 75vw !important;
}

.title {
  display: flex;
  align-items: center;
}

.section-title:before {
  content: url("../../assets/svg/WFPLogoIcon.svg");
  margin-right: 20px;
  margin-top: 5px;
}

.ant-table-row:hover {
  cursor: pointer;
}

.contract-cell {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.booking-list {
  .filters {
    display: flex;
    margin-top: 0;
    background-color: darken($color-grey-dark-2, 2%);
    padding: 15px 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;

    .status-filter-dropdown {
      display: flex;
      column-gap: 30px;
      flex-wrap: wrap;
      row-gap: 20px;
      align-items: flex-start;

      .status-filter {
        min-width: 200px;
      }

      .reset {
        align-self: center;
        background-color: transparent;
        border: none;
      }

      .hbh-select-label {
        color: $color-white !important;
      }
    }

    .input-wrapper {
      width: 400px;
      color: $color-white;
      font-size: 14px;

      .input-label {
        margin-bottom: 5px;
      }

      .search-input {
        width: 100%;
        margin-bottom: 10px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .reset {
      cursor: pointer;
      font-size: 14px;
      width: max-content;
      color: $color-blue-primary;
      text-decoration: underline;
      flex-shrink: 0;
      margin-bottom: 4px;
      margin-right: auto;
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
    }

    .btn-export-data {
      width: max-content;
      flex-shrink: 0;

      &:disabled {
        background-color: darken(#11db30, 20%);
        color: $color-white;
      }
    }
  }
}

.table-requests .hbh-table-dark .ant-table-content {
  .ant-table-thead {
    th {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
      }

      &:nth-last-child(2) {
        text-align: center;
      }
    }
  }

  .ant-table-tbody {
    td {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
      }

      &:nth-last-child(2) {
        text-align: center;
      }
      &:nth-last-child(1) {
        padding-left: 0;
      }
    }
  }
}
