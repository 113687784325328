@import "src/assets/styles/variables";

.progress-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 20px;
  background-color: $color-white;

  .progress-bar-line {
    z-index: 2;
    position: absolute;
    height: 20px;
    left: 0;
    top: 0;
    background-color: $color-malibu;
  }

  .progress-bar-count {
    position: relative;
    z-index: 3;
    color: $color-black;
    cursor: default;
  }
}
