.assignment-wrapper {

  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 28.5px;
  font-size: 13px;
  align-items: center;

  .range-label {
    margin-right: 10px;
  }

  .export-button {
    background-color: #139200;
    width: 150px;
    height: 32px;
    margin-top: 10px;
  }

  .calendar-icon {
    fill: #0A6EB4;
    width: 14px;
    height: 15px;
  }

  .award-toggle {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  strong {
    font-weight: 800;
  }

  .ant-switch-checked {
    background: #005C09;

    .ant-switch-inner {
      font-size: 15px;
      margin: 0px 0px 0 -45px !important
    }

    .ant-switch-handle {
      left: calc(100% - 43px - 2px);
    }
  }


  .switch {
    width: 100px;
    height: 36px;
    border-radius: 9px;

    .ant-switch-inner {
      font-size: 15px;
      margin: 0 0px 0 40px;
    }

    .ant-switch-handle {
      top: 0px;
      width: 36px;
      height: 35px;

      &::before {
        width: 48px;
        height: 36px;
        display: flex;
        justify-content: center;
        border-radius: 9px;
        background-image: url("../../../../assets/svg/award-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
        left: -3px;
        border: 1px solid #146C00;
      }
    }
  }

  .loader-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    font-size: 14px;
  }
}