@import "src/assets/styles/variables";

.wrapper {
  width: 100%;
  padding: $spacer-3;
  cursor: default;
  box-shadow: inset 0px -1px 1px -1px $color-black;
  background-color: $color-grey-dark-4;
  border: 1px solid $color-grey-dark-5;
  border-radius: 4px;
  color: $color-white;

  .title {
    font-size: 20px;

    .note {
      margin: 0 $spacer-1 0 auto;
      font-size: 16px;
    }

    .sum {
      font-weight: 900;
      font-size: 16px;
    }
  }

  .labels {
    display: flex;
    align-items: center;
    margin: $spacer-2 0;
    flex-wrap: wrap;

    .label {
      display: flex;
      align-items: center;
      margin: 0 $spacer-3 $spacer-2 0;

      .dot {
        width: 18px;
        height: 18px;
      }

      .text {
        padding: 0 $spacer-1;
        line-height: 18px;
        cursor: default;
      }
    }
  }

  .line {
    display: flex;

    .block {
      height: $spacer-3;
      position: relative;
      cursor: pointer;
    }
  }
}
