@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.container {
  color: $color-white;
  font-family: "Open Sans";
  width: 90vw;

  .title {
    font-size: 40px;
    padding: $spacer-4 0 $spacer-2;
    line-height: 1.2;
    cursor: default;
  }

  .country {
    font-size: 20px;
    padding: $spacer-2 0 $spacer-4;
    cursor: default;
  }

  .notes {
    font-style: italic;
    margin: 0 0 $spacer-1;
  }

  .content {
    display: flex;

    .widget {
      width: 25vw;

      .box {
        background-color: $color-shark;
        margin: 0 0 $spacer-3;
        border-radius: 12px;
        padding: $spacer-3;
        width: fit-content;
        min-width: 210px;

        .label {
          display: flex;
          align-items: center;
          gap: $spacer-2;
          margin: 0 0 $spacer-2;
          color: $color-silver;

          svg {
            fill: $color-silver;
          }
        }

        .count {
          font-size: 40px;
          line-height: 1;
        }
      }
    }
  }
}
