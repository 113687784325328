@import "src/assets/styles/variables";

.react-datepicker-time__header {
  color: #fff;
}

.react-datepicker__header--time {
  border-radius: 0 !important;
}

.ant-modal-body {
  background-color: #000;
  color: #fff;
}

.ant-modal-header {
  padding: 0 !important;
  border-bottom: none !important;
}

.ant-modal-close-x {
  color: #fff;
}

.email-select {
  margin-top: 15px !important;
  width: 100%;
  height: 100px;
}

.quotation-datepicker-label {
  margin-bottom: 10px;
  display: block;
}

.quotation-submit-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .react-datepicker-time {
    &__input-container,
    &__caption {
      color: #000;
    }
  }
}

.quotation-select-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;

  p {
    font-size: 16px;
  }
}

.quotation-modal-note {
  padding: 50px 0;
  text-align: center;
  font-size: 20px;
  font-style: italic;
  color: #c5c5c5;
  cursor: default;
}

.contract-select-label {
  margin: 0 0 10px;
}

.provider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 35px;
  background-color: #fff;
  border-radius: 16px;
  color: black;
  font-size: 12px;
  margin: 5px;
  padding: 0 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  &.selected {
    background-color: #279a09;
    color: #fff;
  }
}

.providers-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin: 15px 0 30px 0;
}

.select-all-radio {
  color: #fff !important;
  margin-top: 10px !important;
}

.datepicker-container {
  width: 300px;
}

.quotation-date-error {
  color: red;
  margin-right: auto;
  padding: 10px 0 0 10px;
}

.batch-contract-label {
  margin-top: 10px;
  color: lightgray;
  font-size: 13px;
}
