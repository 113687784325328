@import "src/assets/styles/variables";

$cell-height: 40px;

.wrapper {
  position: relative;

  .trigger {
    cursor: pointer;
  }

  .popup {
    box-shadow: 3px 3px 10px 0 rgb(30 42 54 / 60%);
    position: absolute;
    top: $cell-height;
    z-index: 1;
    color: $color-white;
    background-color: $color-gravel;

    .container {
      padding: 5px;
      display: flex;
      width: 500px;
      justify-content: space-between;

      .box {
        width: calc(50% - 3px);
        background-color: darken($color-gravel, 4%);
      }

      .yearSelector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-allports;
        padding: 0 12px;
        height: $cell-height;

        svg {
          opacity: 0.8;
          transition: 0.2s opacity linear;

          &:hover {
            opacity: 1;
          }
        }

        .year {
          font-size: 18px;
          cursor: default;
        }
      }

      .monthsWrapper {
        display: flex;
        flex-wrap: wrap;

        .month {
          width: 33.33%;
          padding: 0 8px;
          height: $cell-height;
          line-height: $cell-height;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
          transition: 0.2s background-color linear;

          &.between {
            background-color: $color-havelock-blue;
          }

          &:hover {
            background-color: lighten($color-havelock-blue, 4%);
          }

          &.active {
            background-color: darken($color-havelock-blue, 10%);
          }
        }
      }
    }

    .apply {
      margin: 0 5px 5px;
      font-size: 16px;
      height: 40px;
      width: calc(100% - 10px);
    }
  }
}

:global(.mode-day) {
  .wrapper {
    .popup {
      background-color: $color-gallery;
      color: $color-mine-shaft;

      .container {
        .box {
          background-color: darken($color-gallery, 2%);
        }

        .yearSelector {
          background-color: darken($color-gallery, 20%);
        }

        .monthsWrapper {
          .month {
            &.between {
              background-color: lighten($color-havelock-blue, 2%);
            }

            &:hover {
              background-color: lighten($color-havelock-blue, 8%);
            }

            &.active {
              background-color: darken($color-havelock-blue, 3%);
              color: $color-white;
            }
          }
        }
      }
    }
  }
}
