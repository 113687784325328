.overall-info {
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
  font-size: 18px;

  .overall-block {
    margin: 0 30px;
    color: #5f5f5f;
    cursor: default;

    span {
      color: #fff;
      margin: 0 0 0 5px;
    }
  }
}

.overall-table {
  .ant-table-cell {
    text-align: center;
  }
}
