@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.pow-sign {
  vertical-align: super;
  font-size: 70%;
}

.general-information {
  border-bottom: 1px solid #262829;
  margin-bottom: 60px;
}

.edit-button {
  height: 45px;
  width: 150px;
  background-color: #3a89f7;

  &.apply {
    background-color: #f77a39;
  }
}

.details-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.dropdown-container {
  margin-left: auto;
}

.booking-details-container {
  .quotation-button {
    margin: 20px 0 0;
  }

  .remarks-item-value {
    white-space: normal;
  }
}

.quotation-button {
  height: 45px;
  width: 250px;
  background-color: #128a00;

  &:disabled,
  &.send:disabled {
    background-color: gray !important;
  }

  &.send {
    text-align: right;
    background-color: #8c9018 !important;
  }
}

.last-row-bolded .ant-table-row:last-child {
  font-weight: 800;
}

.icon-label-container {
  display: flex;
  align-items: center;
}

.destination-icon {
  margin-right: 10px;
}

.hbh-select-error {
  line-height: 1;
}

.booking-details-container {
  small {
    display: block;
    font-size: 11px;
    color: #ff6666;
  }

  .hbh-details-item-value {
    overflow: visible;
  }
}

.hbh-text-error,
.hbh-date-error {
  display: none;
}

.hbh-details-container {
  grid-template-rows: auto !important;
}

.details-label {
  color: $color-white;
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0;
}

.ant-tooltip-content {
  .ant-tooltip-inner {
    text-align: center;
  }
}

.deadline-wrapper {
  width: 66%;
}

.submission-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  .calendar-icon {
    fill: #fff;
  }

  .document-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    fill: $color-white;
  }

  .date-info {
    display: flex;

    .indicator {
      width: 11px;
      height: 11px;
      background-color: #b3b3b3;
      border-radius: 50px;
      display: block;
      margin-top: 7px;
      margin-right: 5px;

      &.green {
        background-color: #1c9e0b;
      }
    }

    .subtext {
      color: #6e7c8b;
      font-size: 12px;
    }
  }
}

.custom-option {
  display: flex;
  align-items: center;

  .custom-option-info {
    color: $color-dusty-gray;
    font-size: 12px;
    margin-left: 5px;
  }
}

.async-select__option {
  .contract-option {
    text-wrap: wrap;
  }
}

.async-select__single-value {
  .contract-option {
    @include text-cropping;
  }
}

.request-details-header {
  display: flex;
  justify-content: space-between;

  .hbh-details-container {
    width: 66%;
  }
}
