@import "~antd/dist/antd.css";
@import "@ui-modules/assets/styles/normalize.scss";
@import "@ui-modules/assets/styles/common.scss";
@import "./variables.scss";
@import "./mixins.scss";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

.hbh-service-name {
  h1 {
    letter-spacing: 0.2px !important;
    font-size: 24px !important;
  }

  h3 {
    letter-spacing: 2.5px !important;
  }
}

.btn {
  width: 100%;
}

.bold {
  font-weight: 700;
}

.not_processed,
.closed {
  background-color: $color-red-dark-1;
  border-color: $color-red-dark-1;

  &:hover {
    background-color: lighten($color-red-dark-1, 10%);
    border-color: lighten($color-red-dark-1, 10%);
  }
}

.quotes_request_sent {
  background-color: $color-cyan;
  border-color: $color-cyan;

  &:hover {
    background-color: lighten($color-cyan, 10%);
    border-color: lighten($color-cyan, 10%);
  }
}

.bid_closed {
  background-color: $color-limeade;
  border-color: $color-limeade;

  &:hover {
    background-color: lighten($color-limeade, 10%);
    border-color: lighten($color-limeade, 10%);
  }
}

.processed {
  background-color: $color-green-grass;
  border-color: $color-green-grass;

  &:hover {
    background-color: lighten($color-green-grass, 10%);
    border-color: lighten($color-green-grass, 10%);
  }
}

.assigned {
  background-color: $color-sky-light-1;
  border-color: $color-sky-light-1;
  color: $color-white;

  &:hover {
    background-color: lighten($color-sky-light-1, 10%);
    border-color: lighten($color-sky-light-1, 10%);
  }
}

.cancelled {
  background-color: $color-grey-dark-7;
  border-color: $color-grey-dark-7;

  &:hover {
    background-color: lighten($color-grey-dark-7, 10%);
    border-color: lighten($color-grey-dark-7, 10%);
  }
}

.assignment_notified {
  background-color: $color-fun-green;
  border-color: $color-fun-green;

  &:hover {
    background-color: lighten($color-fun-green, 10%);
    border-color: lighten($color-fun-green, 10%);
  }
}

.hbh-wrapper .booking-details-container {
  width: 75vw !important;
}

.grid-full-column {
  grid-column: 1 / -1;
}

.two-columns-grid {
  grid-template-columns: 1fr 1fr !important;
}

@media (max-width: 850px) {
  .two-columns-grid {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 1140px) {
  .grid-half-column {
    grid-column: inherit;
  }
}

.hbh-details-container {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.booking-details-title {
  margin-top: 40px !important;
  margin-left: 12px !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.booking-details-container {
  background-color: $color-grey-middle-2;
  border-radius: $border-radius-small;
  color: $color-white;
  padding: 40px;
  margin: 0 auto 40px !important;
  min-height: 400px;
  width: 1080px !important;

  .details-header {
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
  }
}

.hbh-wrapper {
  width: unset !important;
}

.table-scrolling {
  .ant-table {
    overflow-x: auto;

    .ant-table-container {
      min-width: 1200px;
    }
  }
}

.rs--is-disabled {
  .rs__control--is-disabled {
    background-color: $color-alto;
    .rs__single-value {
      color: $color-grey-middle-3;
    }
    .rs__dropdown-indicator {
      svg {
        fill: $color-grey-light-3;
      }
    }
  }
}

// Global styles for Modal (using Antd)
.modal-title {
  background-color: $color-denim;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: $color-white;
  z-index: 222222;
}

.modal-content-text {
  line-height: 1.4;
  font-size: 18px;

  &.centered {
    text-align: center;
  }

  p {
    margin: 0 0 $spacer-3;
  }

  b {
    font-weight: 700;
  }
}

.modal-textarea {
  width: 100%;
  resize: none;
  height: 70px;
  color: $color-black;
}

.modal-loader {
  width: 100%;
  margin: 30px auto !important;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $spacer-3 0 0;

  &.hidden {
    display: none;
  }

  button {
    min-width: 140px;
    height: 35px;
    font-size: 16px;

    &:only-child {
      width: 100%;
    }
  }
}

// Note: fix tooltips in table, that was under th titles
.ant-table-column-title {
  z-index: auto;
}

#global-tooltip {
  background-color: $color-black;
  z-index: 1001; // Above .ant-modal-wrap = 1000
}
