.batch-details {
  .hbh-page-title {
    flex-wrap: wrap;
  }

  .batch-list-contract {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hbh-filters {
    width: 100%;
  }

  .action-buttons {
    display: flex;
    column-gap: 30px;
    align-self: center;
    .export-button {
      justify-self: flex-end;
    }
  }

  .bulk-checkbox {
    color: green;

    .ant-checkbox-inner {
      background-color: transparent;
      border-color: #00ae06 !important;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #00ae06 !important;
        border-color: #00ae06 !important;
      }
    }
  }

  .bulk-request-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    color: #fff;

    .bulk-request-button {
      margin-bottom: 4px;
      height: 30px;
    }
  }

  .title {
    font-weight: 300;
    font-size: 25px;
    line-height: 34px;
    font-family: "Open Sans", sans-serif;

    strong {
      font-weight: 400;
    }
  }
}

.bulk-dropdown {
  z-index: 500 !important;
}

.assigned-notification-modal {
  font-size: 16px;

  .label {
    text-align: center;
  }

  .buttons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .cancel-button {
      background-color: #8a4e00;
      border-color: #8a4e00;
    }

    button {
      width: 150px;
      height: 30px;
    }
  }
}

.table-batch-requests .hbh-table-dark .ant-table-content {
  .ant-table-thead {
    th {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
      }

      &:nth-last-child(2) {
        text-align: center;
      }
    }
  }

  .ant-table-tbody {
    td {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
      }

      &:nth-last-child(2) {
        text-align: center;
      }
      &:nth-last-child(1) {
        padding-left: 0;
      }
    }
  }
}
