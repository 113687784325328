@import "../../assets/styles/variables";

.vendor-details-page {
  .content {
    background-color: #333536;
    border-radius: 0 0 4px 4px;
    color: #fff;
    padding: 40px;
    margin: 0 auto 40px;
    min-height: 400px;
    width: 75vw;
  }
}
