@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.wrapper {
  :global .ant-switch-checked {
    background: #005c09;

    .ant-switch-inner {
      font-size: 15px;
      margin: 0px 0px 0 -45px !important;
    }

    .ant-switch-handle {
      left: calc(100% - 43px - 2px);
    }
  }

  .switch {
    width: 100px;
    height: 30px;
    border-radius: 9px;

    :global {
      .ant-switch-inner {
        font-size: 15px;
        margin: 0 0px 0 40px;
      }

      .ant-switch-handle {
        top: 0px;
        width: 30px;
        height: 30px;

        &::before {
          width: 48px;
          height: 30px;
          display: flex;
          justify-content: center;
          border-radius: 9px;
          background-position: center;
          background-repeat: no-repeat;
          left: -3px;
          border: 1px solid #146c00;
        }
      }
    }

    &.signIcon {
      :global {
        .ant-switch-handle {
          &::before {
            background-image: url("../../assets/svg/e-sign.svg");
          }
        }
      }
    }

    &.awardIcon {
      :global {
        .ant-switch-handle {
          &::before {
            background-image: url("../../assets/svg/award-icon.svg");
          }
        }
      }
    }
  }
}
