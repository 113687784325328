@import "../../../../../../assets/styles/variables";

.information-container {
  margin-bottom: 60px;
  border-bottom: 1px solid #262829;

  h3 {
    color: $color-white;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin: 20px 0;
  }

  .information-block {

    .information-header {
      display: flex;
      column-gap: 15px;
      align-items: center;
    }

    .transport-breakdown-wrapper {
      display: flex;
      column-gap: 15px;
      align-items: center;

      .delete-icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        fill: red;
      }

      .breakdown_button {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .upload-breakdown-button {
    border-radius: 10px;
    width: 300px;
    height: 40px;

    svg {
      fill: #fff;
      width: 15px;
      height: 15px;
    }
  }

  .information-table {
    margin: 0 0 20px;
  }

  .hbh-table-dark .ant-table-content .ant-table-tbody td {
    overflow: visible;
    vertical-align: middle;
    padding: 10px 8px;

    &:empty {
      padding: 0;
    }
  }

  .hbh-table .ant-spin-nested-loading {
    overflow: visible;
  }

  .close-button {
    padding: 0;
    height: auto;
    background: none;
    border: none;

    svg {
      fill: #f00;
      width: 20px;
      height: 20px;
    }
  }

  .hbh-select {
    width: 115px;
  }

  .ant-table-thead > tr > th {
    padding: 16px 8px;
  }

  .ant-table-cell {
    small {
      position: absolute;
      line-height: 1;
      font-size: 9px;
    }
  }

  .add-button {
    margin: 10px 0;
  }
}

.destination-icon {
  display: inline-block;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: 100;
  cursor: default;
  margin-right: 10px;
}

.deletion-modal {

  .loader {
    width: 100%;
    margin: 40px auto 0;
  }

  .labels-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    .title, .warning {
      font-size: 16px;
      font-weight: 222;
    }

    .warning {
      text-decoration: underline;
      text-underline-offset: 8px;
    }

    .destination-details {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.dest {
  &-a {
    background-color: $color-scooter;
  }
  &-b {
    background-color: $color-blue-bell;
  }
  &-c {
    background-color: $color-mrviolet;
  }
  &-d {
    background-color: $color-green-haze;
  }
  &-e {
    background-color: $color-egg-blue;
  }
  &-f {
    background-color: $color-barley-corn;
  }
  &-g {
    background-color: $color-astral;
  }
  &-h {
    background-color: $color-indochine;
  }
}
