// Main
$color-black: #000;
$color-white: #fff;

// Grey
$color-grey-dark-1: #3f424e;
$color-grey-dark-2: #434a4e;
$color-grey-dark-3: #1e2a36;
$color-grey-dark-4: #262829;
$color-grey-dark-5: #171717;
$color-grey-dark-6: #161616;
$color-grey-dark-7: #141414;
$color-grey-light-1: #c5d3e2;
$color-grey-light-2: #e0e8ed;
$color-grey-light-3: #b8bfc4;
$color-grey-light-4: #ccc;
$color-grey-middle-1: #6e7c8b;
$color-grey-middle-2: #333536;
$color-grey-middle-3: #323536;
$color-grey-middle-4: #4e4e4e;
$color-grey-middle-5: #2a2d34;

// Other colors
$color-blue-primary: #0288ff;
$color-blue-secondary: #3a89f7;
$color-blue-dark-1: #095d93;
$color-blue-dark-2: #075287;
$color-blue-light: #f0f7fd;
$color-green-middle: #417505;
$color-green-grass: #0dac26;
$color-cyan: #26b3a5;
$color-red-dark-1: #b3262b;
$color-red-light-1: #ff6666;
$color-sky-light-1: #52a5ff;
$color-malibu: #7ac1ff;
$color-sky-light-2: #0a93db;
$color-blue-dark-3: #0a3adb;
$color-yellow: #eac810;
$color-scooter: #3bb2d0;
$color-blue-bell: #8a8bc9;
$color-mrviolet: #a3399b;
$color-green-haze: #009149;
$color-egg-blue: #00deb9;
$color-barley-corn: #9b9456;
$color-astral: #327db5;
$color-indochine: #bf6604;
$color-fun-green: #007147;
$color-limeade: #6b9400;
$color-brand: #007dbc;
$color-alto: #d8d8d8;
$color-shark: #1f242a;
$color-silver: #cdcdcd;
$color-gravel: #464547;
$color-allports: #0068a0;
$color-havelock-blue: #4e94e2;
$color-gallery: #ededed;
$color-mine-shaft: #333333;
$color-primary: #0080ff;
$color-dodger-blue: #3a89f7;
$color-selago: #f0f7fd;
$color-mirage: #1e2a36;
$color-cerulean: #0b9dd9;
$color-dusty-gray: #9a9a9a;
$color-loblolly: #c1c7cd;
$color-denim: #0a6eb4;

// Miscellaneous

$border-radius-small: 4px;

// Spaces
$spacer: 8px;
$spacer-0: 0; // 0
$spacer-1: $spacer * 0.5; // 4px
$spacer-2: $spacer; // 8px
$spacer-3: $spacer * 2; // 16px
$spacer-4: $spacer * 3; // 24px
$spacer-5: $spacer * 4; // 32px
$spacer-6: $spacer * 5; // 40px
